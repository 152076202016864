
const AssetPaths = {"loading.gif":"/assets/loading-25a0be082d625832631400bfb4541a565d989ca1d86809c640412bfc9a224c83.gif","whats_notes.png":"/assets/whats_notes-325d32177abb7f7cd2c20163fe0486cafdbaa7588daf7e7f5b7e8e91b7b7dac5.png","download.svg":"/assets/download-0d0d2fb482e2d46fe9100f5add992919a4170932d7a87ca452341714a465df46.svg","delete.svg":"/assets/delete-1f46712a957b0975c3a34df606eda3962d040d69365380156a3ed4e354dceb01.svg","files-empty.svg":"/assets/files-empty-92b73a7684ffce60aaad95e69f155ab6ddc85b71a73a6f9a7efe075c2c4d7f5e.svg","influence-icon.svg":"/assets/influence-icon-128cc091c43a3e7eef2e4ed9bd7c0fd7000ec6abb8060f7cca17b4741e62a0c3.svg","messages_blank.svg":"/assets/messages_blank-1bed8d4ac9db4165d2130502f49e28c6b7d57c2fce50a2d824cdc7545f5cb741.svg","no-messages.svg":"/assets/no-messages-09645bc7960d882649b38ccc0a84ba1ccdb82556b684f599d352945e3cb6bd08.svg","influence_header_logo_icon.png":"/assets/influence_header_logo_icon-33fefa110723a1666de3d028994227cc172f679a5273cd251ae2f973c692543e.png","search.svg":"/assets/search-46b971300a350a0b1e41ed2697676f8b9a4b058540c1799b98b8211deea33607.svg","search-empty-state.svg":"/assets/search-empty-state-499fa9775747fe9840137970855bbc59abff0774ab42783ffbcf560d662cc1d7.svg","header-search-icon.png":"/assets/header-search-icon-868f651f51a4793b0f8aa9c93b0d27b86cfab3b2b8639001db5eb44ba2edad4f.png","undraw-walking-outside-5-ueb.svg":"/assets/undraw-walking-outside-5-ueb-f18df04d19bde06a40d4f8220ce6f4098ab9beaa76e787d4212c670899a1b4c0.svg"};
const RailsHelpers =  {
  giphyAPIKey: "qwA6xwbqZwHNDw98RpP2EgJ7QwAI9v2P",
  rollbarKey: "a8cbe1d4947b413894aded6c05799ffe",
  currentEnvironment: "staging",
  rollbarEnabled: true,
  rollbarCatchAll: false,
  notesListPageLimit: 25,
  conversationListPageLimit: 40,
  notificationsListPageLimit: 20,
  questionsPageLimit: 20,
  chatPageLimit: 50,
  usernameRegex: new RegExp(/(?:^|[^\w])(?:@)([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)/g),
  workflowStages: {"accepted":"Accepted","negotiation":"Negotiation","delivery":"Delivery","creating":"Creating","review":"Review","publishing":"Publishing","live":"Live","paid":"Paid","dropped":"Dropped"},
  assetUrl: (asset) => {
    if(!AssetPaths[asset]){
      console.log(`Need to add ${asset} to RailsHelpers->ASSETS`);
    }else{
      return AssetPaths[asset]
    }
  }
}

module.exports = RailsHelpers;
