

let esc = (str) => { return encodeURIComponent(str) };

const UrlHelpers =  {
  adDataPath: (type) => {
    return `/go/api/ads?ad=${type}`;
  },
  adTrackingPath: (id) => {
    return `/go/t/a/id/${id}`;
  },
  feedPath: () => {
    return `/go/feed`;
  },
  discoveryPath: () => {
    return `/go/discovery`;
  },
  referPath: () => {
    return `/influencers/pro/refer`;
  },
  upgradeLandingPageUrl: (user) => {
    return `https://try.influence.co/pages/bpro`;
  },
  editProfilePath: () => {
    return `/influencers/profile/edit`;
  },
  influencerProfilePath: (username) => {
    return "/username"
      .replace('username', username);
  },
  newConversationPath: (current_account_id, recipient_id) => {
    return `/influencers/replace_account_id/conversations/new?recipient_id=replace_recipient_id`.
            replace("replace_account_id", current_account_id).
            replace("replace_recipient_id", recipient_id);
  },
  influencersNotesPath: (sort, lastValue) => {
    let template = `/influencers/notes`;
    let params = [];

    if(sort){
      params.push(`sort=${sort}`);
    }
    if(lastValue){
      params.push(`last_value=${lastValue}`)
    }

    return `${template}?${params.join('&')}`;
  },
  influencerNotificationsPath: (last_id) => {
    return `/go/api/notifications?last=${last_id}`;
  },
  influencerNotificationsUnseenCountPath: () => {
    return `/go/api/notifications/unseen_count`;
  },
  influencerSetNotificationReadPath: (notification_id) => {
    return `/go/api/notifications/${notification_id}/read`;
  },
  influencerNotificationsResetUnseenCountPath: () => {
    return `/go/api/notifications/reset_unseen_count`;
  },
  influencerSetAllNotificationsReadPath: (last_id) => {
    return `/go/api/notifications/read_all?first=${last_id}`;
  },
  influencerCampaignApplicationsPath: (filter) => {
    let template = "/influencers/campaign_applications";
    let params = [];

    if(filter){
      params.push(`filter=${filter}`);
    }

    return `${template}?${params.join('&')}`;
  },
  campaignsPath: () => {
    return `/go/campaigns`;
  },
  myCampaignsPath: () => {
    return `/go/campaigns/me`;
  },
  campaignsAvailablePath: () => {
    return `/go/api/campaigns/available`;
  },
  currentAccountCampaignsPath: () => {
    return `/go/api/campaigns/account_campaigns`;
  },
  usernameInfluencerSearchesPath: () => {
    return "/influencer_searches/username";
  },
  influencerSearchesPath: () => {
    return "/influencer_searches";
  },
  advancedInfluencerSearchesPath: () => {
    return "/influencer_searches/advanced";
  },

  sendMessagePath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/message`;
  },
  messagesPath: (messagingAccountId, context) => {
    return `/go/api/conversations/${messagingAccountId}/messages?context=${context}`;
  },
  influencerConversationsPath: (influencer_id) => {
    return `/influencers/:influencer_id/conversations`.
            replace(":influencer_id", influencer_id);
  },
  conversationArchivePath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/archive`;
  },
  conversationUnarchivePath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/unarchive`;
  },
  conversationStarPath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/star`;
  },
  conversationUnstarPath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/unstar`;
  },
  conversationSearchPath: (search) => {
    return `/go/api/conversations/search?query=${search}`;
  },
  conversationBlockSettingAPIPath: (messagingAccountId) => {
    return `/go/api/conversations/${messagingAccountId}/block_setting`;
  },
  conversationsPath: (filter, campaignId, lastValue) => {
    let template = "/go/api/conversations/list";
    let params = [];

    if(filter){
      params.push(`filter=${filter}`);
    }
    if(campaignId){
      params.push(`campaign_id=${campaignId}`)
    }

    if(lastValue){
      params.push(`last_value=${lastValue}`)
    }

    return `${template}?${params.join('&')}`;
  },
  searchNotesAboutPath: () => { return "/influencers/notes/about_search"; },
  searchNotesUsernamePath: () => { return "/influencers/notes/username_search"; },
  notePath: (id) => {
    return "/influencers/notes/:n_id"
      .replace(':n_id', id);
  },
  accountSidebarDataPath: (account_id) => {
    return "/influencers/:account_id/sidebar_data"
      .replace(':account_id', account_id);
  },
  notesPath: (id) => {
    return "/influencers/notes?about_id=about_id_placeholder"
      .replace("about_id_placeholder", id);
  },
  noteAttachmentPath: (id, attachmentId) => {
    return "/influencers/notes/:n_id/attachments/:a_id"
      .replace(':n_id', id)
      .replace(':a_id', attachmentId)
  },
  noteAttachmentsPath: (id) => {
    const urlTemplate = "/influencers/notes/:note_id/attachments.json";
    return urlTemplate.replace(":note_id", id);
  },
  followsPath: (followed_id, source) => {
    source = source || 'follow'
    return "/go/follows?followed=followed_id&source=source_value"
      .replace('followed_id', followed_id).replace('source_value', source)
  },
  followPath: (followed_id, source) => {
    source = source || 'follow'
    return "/go/follows/followed_id?source=source_value"
      .replace('followed_id', followed_id).replace('source_value', source)
  },
  suggestedFollowsPath: () => {
    return "/go/follows/suggested"
  },
  trendingFollowsPath: () => {
    return "/go/follows/trending"
  },
  allSuggestedFollowsPath: () => {
    return "/go/follows/suggestions"
  },
  goSelectPath: () => {
    return "/go/select";
  },
  goProPath: () => {
    return "/go/pro";
             },
  recentSearchesPath: (accountId) => {
    return `/go/api/globalsearch/${accountId}/recent`;
  },
  searchPath: (term) => {
    return `/go/api/globalsearch/search?term=${term}`;
  },
  accountsHoverModalPath: (username) => {
    return `/go/api/accounts/profile?username=${username}`;
  },
  topPostsPath: () => {
    return `/go/api/posts/top`;
  },
  latestNewsPath: () => {
    return `/go/api/nofilter/latest`;
  },
  featuredNewsPath: () => {
    return `/go/api/nofilter/featured`;
  },
  workflowMemberPath: (influencer_id, campaign_id, member_id) => {
    return  "/influencers/influencer_id/campaigns/campaign_id/workflow_members/member_id"
      .replace('influencer_id', influencer_id)
      .replace('campaign_id', campaign_id)
      .replace('member_id', member_id);
  },
  campaignApplicationPath: (influencer_id, campaign_id, application_id) => {
    return  "/influencers/influencer_id/campaigns/campaign_id/applications/application_id"
      .replace('influencer_id', influencer_id)
      .replace('campaign_id', campaign_id)
      .replace('application_id', application_id);
  },
  bulkCampaignApplicationActionPath: (influencer_id, campaign_id, application_id) => {
    return  "/influencers/influencer_id/campaigns/campaign_id/applications/bulk_action"
      .replace('influencer_id', influencer_id)
      .replace('campaign_id', campaign_id);
  },
  businessPostEngagementsPath: () => {
    return `/go/api/business/posts-engagements`;
  },
  influencerCampaignsPublishedPath: () => {
    return `/go/api/campaigns/published`;
  },
  influencerCampaignStatsPath: () => {
    return `/go/api/campaigns/influencer-stats`;
  },
  businessCampaignsCreatedCountPath: () => {
    return `/go/api/campaigns/business-created`;
  },
  businessLiveCampaignStatsPath: () => {
    return `/go/api/campaigns/live-stats`;
  },
  businessCampaignsPath: () => {
    return `/influencers/show/campaigns`;
  },
  createCampaignPath: () => {
    return `/influencers/id/campaigns/new`;
  },
  spacesAPIPath: () => {
    return `/go/api/spaces/`;
  },
  spaceQuestionsAPIPath: () => {
    return `/go/api/spaces/questions`;
  },
  questionAPIPath: (question_id) => {
    return `/go/api/spaces/questions/${question_id}`;
  },
  spaceQuestionCreateAPIPath: (space_id) => {
    return `/go/api/spaces/questions/?space_id=${space_id}`;
  },
  spaceMembershipCreateAPIPath: (space_id) => {
    return `/go/api/spaces/memberships?space_id=${space_id}`;
  },
  spaceMembershipDestroyAPIPath: (space_id) => {
    return `/go/api/spaces/${space_id}/membership`;
  },
  voteAPIPath: () => {
    return `/go/api/votes/`;
  },
  questionAnswersAPIPath: (question_id) => {
    return `/go/api/spaces/questions/${question_id}/answers`;
  },
  questionAnswerCreateAPIPath: (question_id) => {
    return `/go/api/spaces/answers?question_id=${question_id}`;
  },
  answerAPIPath: (answer_id) => {
    return `/go/api/spaces/answers/${answer_id}`;
  },
  commentAPIPath: (comment_id) => {
    return `/go/api/comments/${comment_id}`;
  },
  questionPath: (question_slug) => {
    return "/go/questions/question_slug"
      .replace('question_slug', question_slug);
  },
  spacePath: (space_id) => {
    return "/go/answers/space_id"
      .replace('space_id', space_id);
  },
  spacesPath: () => {
    return "/go/answers";
  },
  filteredSpacesPath: (space_id, filter) => {
    return "/go/answers/space_id/filter"
           .replace("space_id", space_id)
           .replace("filter", filter);
  },
  flagsAPIPath: (key, value) => {
    return `/go/api/flags?key=${key}&value=${value}`;
  },
  getCommentsAPIPath: (commentable_id, commentable_type) => {
    return `/go/api/comments?commentable_id=${commentable_id}&commentable_type=${commentable_type}`;
  },
  commentsAPIPath: () => {
    return `/go/api/comments`;
  },
  deleteCommentAPIPath: (id) => {
    return `/go/api/comments/${id}`;
  },
  fireballStatusPath: (likeableId, likeableType) => {
    return `/go/api/fireballs/${likeableId}/${likeableType}/status`;
  },
  fireballsAPIPath: () => {
    return `/go/api/fireballs`;
  },
  adminEditAnswerPath: (answer_id) => {
    return `/go/rails-admin/space~answer/${answer_id}/edit`;
  },
  adminEditQuestionPath: (question_id) => {
    return `/go/rails-admin/space~question/${question_id}/edit`;
  },
  adminActivityStatusPath: (activity_id) => {
    return `/go/api/admin/activities/${activity_id}/status`;
  },
  adminTogglePinnedPath: (activity_id) => {
    return `/go/api/admin/activities/${activity_id}/toggle-pin`;
  },
  adminToggleSuggestedPath: (activity_id) => {
    return `/go/api/admin/activities/${activity_id}/toggle-suggested`;
  },
  suggestUsernameAPIPath: (username) => {
    return `/go/api/accounts/username-suggest?username=${username}`;
  },
  chatLandingPath: () => {
    return `/go/communities`;
  },
  chatChannelLandingPath: (channel_id) => {
    return `/go/communities/channel/channel_id`.
            replace("channel_id", channel_id);
  },
  chatShopLandingPath: (shop_id) => {
    return `/go/communities/shop/shop_id`.
            replace("shop_id", shop_id);
  }, 
  chatShopInviteUrl: (invite_key) => {
    return `https://staging.influence.co/go/invite/invite_key`.
            replace("invite_key", invite_key);
  }
}

module.exports = {...UrlHelpers, ...{APIPostChatShopsPath: () => {
  return `/go/api/chat/shops`;
},
APIPutChatShopsPath: (id) => {
  return `/go/api/chat/shops/${id}`;
},
APIDeleteChatShopsPath: (id) => {
  return `/go/api/chat/shops/${id}`;
},
APIGetChatShopsMembershipsPath: (shop_id) => {
  return `/go/api/chat/shops/memberships?shop_id=${shop_id}`;
},
APIPutChatShopsMembershipsNotificationSettingsPath: (shop_id) => {
  return `/go/api/chat/shops/memberships/${shop_id}/notification_settings`;
},
APIGetChatChannelsPath: (shop_id) => {
  return `/go/api/chat/channels?shop_id=${shop_id}`;
},
APIPostChatChannelsPath: () => {
  return `/go/api/chat/channels`;
},
APIGetChatChannelsAutocompletePath: (id,query) => {
  return `/go/api/chat/channels/${id}/autocomplete?query=${query}`;
},
APIPutChatChannelsPath: (id) => {
  return `/go/api/chat/channels/${id}`;
},
APIDeleteChatChannelsPath: (id) => {
  return `/go/api/chat/channels/${id}`;
},
APIGetChatChannelsMessagesPath: (channel_id,first) => {
  return `/go/api/chat/channels/${channel_id}/messages?first=${first}`;
},
APIPostChatChannelsMessagesPath: (channel_id) => {
  return `/go/api/chat/channels/${channel_id}/messages`;
},
APIPutChatChannelsMessagesPath: (channel_id,id) => {
  return `/go/api/chat/channels/${channel_id}/messages/${id}`;
},
APIDeleteChatChannelsMessagesPath: (channel_id,id) => {
  return `/go/api/chat/channels/${channel_id}/messages/${id}`;
},
APIDeleteChatShopsMembershipsPath: (shop_id,account_id) => {
  return `/go/api/chat/shops/${shop_id}/memberships/${account_id}`;
},
APIGetChatChannelsMembershipsPath: (channel_id) => {
  return `/go/api/chat/channels/${channel_id}/memberships`;
},
APIPostChatResetChannelCountPath: (channel_id) => {
  return `/go/api/chat/reset_channel_count/${channel_id}`;
},
APIPostChatResetUserUnseenCountPath: () => {
  return `/go/api/chat/reset_user_unseen_count`;
},
APIGetChatProfilePath: (username,shop_id) => {
  return `/go/api/chat/profile/${username}?shop_id=${shop_id}`;
},
APIPostChatReactionsPath: () => {
  return `/go/api/chat/reactions`;
},
APIDeleteChatReactionsPath: () => {
  return `/go/api/chat/reactions`;
},
APIPostChatAccountDeviceTokenPath: () => {
  return `/go/api/chat/account/device_token`;
},
APIPostChatUserSignInPath: () => {
  return `/go/api/chat/user/sign_in`;
},
APIPostChatUserPath: () => {
  return `/go/api/chat/user`;
},
APIPostChatUserResendCodePath: (id) => {
  return `/go/api/chat/user/${id}/resend_code`;
},
APIPostChatUserStartOverPath: (id) => {
  return `/go/api/chat/user/${id}/start_over`;
},
APIPutChatUserVerifyPath: (id) => {
  return `/go/api/chat/user/${id}/verify`;
},
APIPutChatUserSetUsernamePath: (id) => {
  return `/go/api/chat/user/${id}/set_username`;
},
APIPostChatUserJoinShopPath: (id) => {
  return `/go/api/chat/user/${id}/join_shop`;
},
APIPutChatUserPath: (id) => {
  return `/go/api/chat/user/${id}`;
},
APIPostPhoneNumberVerificationsPath: () => {
  return `/go/api/phone_number_verifications`;
},
APIPutPhoneNumberVerificationsPath: (id) => {
  return `/go/api/phone_number_verifications/${id}`;
},
APIDeletePhoneNumberVerificationsPath: (id) => {
  return `/go/api/phone_number_verifications/${id}`;
},
APIGetAccountsProfilePath: (username) => {
  return `/go/api/accounts/profile?username=${username}`;
},
APIGetAccountsUsernameSuggestPath: (username) => {
  return `/go/api/accounts/username-suggest?username=${username}`;
},
APIPutAccountsMePath: () => {
  return `/go/api/accounts/me`;
},
APIGetLocationsPath: (search) => {
  return `/go/api/locations?search=${search}`;
},
APIDeleteListsRemoveUserPath: (list_id) => {
  return `/go/api/lists/${list_id}/remove_user`;
}}};
